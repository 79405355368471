<script>
// eslint-disable-next-line no-unused-vars
import TablePage from '../../../../../../components/table_page';
// eslint-disable-next-line no-unused-vars
import request from '../../../../../../utils/request';

export default {
  name: 'table-component',
  extends: TablePage,
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(val) {
        /** @todo 监听传过来的对象，把参数赋值给她 */
        if (val) {
          this.params = {
            realName: val.realName,
            userName: val.userName,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      requestUrl: '/sfa/sfaSignFormsController/leaveApplyDetail',
    };
  },
  created() {
    this.getConfigList('sfa_attendance_leave_summary_detailList').then(() => {
      /** @desc 清空展示的查询参数列表 */
      this.searchList = [];
    });
  },
};
</script>
