<script>
import Form, { formCreate } from '../../../../../components/form';
import DetailList from './components/detail_list_table.vue';

formCreate.component('sfaAttendanceLeaveSummaryCheckList', DetailList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    /** @desc 设置详情的value值 */
    setDetailValue() {
      this.setValue({
        row: { ...this.formConfig.row },
      });
    },
  },
  async created() {
    await this.getFormRule('sfa_attendance_leave_summary_checklist');
    this.setDetailValue();
  },
};
</script>
<style lang="less" scoped>
/deep/ .page{
  padding-bottom: 36px;
}
</style>
